import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "components/Layout"
import ContactForm from "components/ContactForm"
import HomeOmahaGyms from "components/HomeOmahaGyms"
import Seo from "components/Seo"

const getImages = graphql`
  {
    omahaGyms: file(
      relativePath: { eq: "fitfarm-omaha-gyms-locations-bg-1.webp" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ContactUs = () => {
  const imgData = useStaticQuery(getImages)
  return (
    <Layout>
      <Seo
        title="Contact Fitfarm"
        description="Have a question or are you ready to join?"
        keywords={[]}
        pathname={"/contact-us/"}
      />
      <article>
        <ContactForm />
        <HomeOmahaGyms sectionImage={imgData.omahaGyms} />
      </article>
    </Layout>
  )
}

export default ContactUs
